<template>
  <div class="privacy">

    <div class="title">隐私政策</div>

    <h5>本版发布日期：2021年10月31日</h5>
    <h5>生效日期：2021年11月1日</h5>
    <h5></h5>
    <div>
      为切实保护测量大师用户个人信息，优化用户体验，测量大师（以下称“我们”，指无锡中巢建材科技有限公司）根据现行法规及政策，制定《测量大师个人信息保护政策》（以下简称“本政策”）。本政策将详细说明我们在获取、使用、管理及保护用户个人信息方面的政策及措施。
    </div>
    <h5>
      我们努力用通俗易懂、简明扼要的文字进行表达，并对本政策中与您的权益密切相关的条款以及本政策中所涉及的个人敏感信息，采用粗体字进行标注以提示您注意。因此，在您使用测量大师前，请务必仔细阅读并充分理解本政策，在确认充分理解并同意后再使用测量大师服务。
    </h5>
    <h5>
      本政策适用于我们向您提供的所有服务，无论您是通过计算机设备、移动终端还是其他设备获得的测量大师服务。本政策不适用于：
    </h5>
    <h5>
      通过测量大师接入的第三方产品或服务（包括任何第三方网站）收集的信息，可能包括在测量大师中链接到的其他产品或服务；
    </h5>
    <h5>
      帮助测量大师进行广告宣传的第三方收集的信息；以及在测量大师中开展广告活动的第三方所收集的信息。
    </h5>
    <h5>
      您使用这些第三方服务（包括您向这些第三方提供的任何个人信息），将受这些第三方的服务条款及隐私政策约束（而非本政策），具体规定请您仔细阅读第三方的相关条款。请您妥善保护您的个人信息，仅在必要的情况下向第三方提供。
    </h5>
    <h5>
      请您了解，本政策中所述的客户端及相关服务可能会根据您装载客户端设备的型号、系统版本、浏览器版本、软件应用程序版本等因素而有所不同。最终的产品和服务以您所使用的测量大师客户端为准。
    </h5>
    <h5>本政策将帮助您了解以下内容：</h5>

    <h5>1、我们如何收集和使用您的个人信息</h5>
    <h5>2、Cookie及相关技术告知</h5>
    <h5>3、我们如何委托处理、共享、转让及公开披露您的个人信息</h5>
    <h5>4、我们如何保护您的个人信息</h5>
    <h5>5、我们如何存储您的个人信息</h5>
    <h5>6、您的个人信息主体权利</h5>
    <!-- <h5>7、我们如何收集和处理未成年人的个人信息</h5> -->
    <h5>7、本政策的更新</h5>
    <h5>8、自启动或关联启动说明</h5>
    <h5>9、如何联系我们</h5>

    <h3>1 我们如何收集和使用您的个人信息</h3>
    <p>1.1 您自愿向我们提供的个人信息</p>
    <p>
      1.1.1
      在您注册测量大师账号或使用账号管理功能添加其他账号时，我们会通过注册和登录流程收集您的账户昵称、密码、密码保护选项、手机号码。
    </p>
    <p>
      1.1.2
      若您以其他方式关联登录测量大师，我们会向关联第三方请求您的个人信息，对于我们要求但第三方无法提供的个人信息，我们仍可能会要求您提供：（1）当您使用微信账号登录时，我们会获取您的微信头像、微信昵称、性别和地区信息；（2）当您使用Apple
      ID登录时，我们会获取您Apple
      ID的电子邮箱地址。为了确保账号安全和落实网络实名制，在您使用其他方式关联登录、使用测量大师的某些功能时，您可能仍然需要绑定有效的手机号码。
      <span>收集这些信息是为了帮您创建测量大师账号和对您进行实名验证，如果您拒绝提供这些信息，将影响您注册使用测量大师。</span>
    </p>
    <p>
      1.1.3
      为了您可以便捷地登录、使用新格尔集团的产品，您可以使用测量大师账号登录、使用测量大师以及新格尔集团的其他的部分产品。
    </p>
    <p>
      1.1.4
      在您创建项目、添加好友、添加客户信息时，我们将收集您发布的内容（包括文字、图片或视频）。如您在创建项目时选择地理位置，我们会收集您的位置信息，<span>如果您拒绝提供位置信息，你创建的项目中不会显示项目地址，同时导致其他人员看不到项目地址。</span>
    </p>
    <p>
      1.1.5
      你填写的业主姓名、电话、客户来源信息、项目经理信息用来展示给项目的所有人查看，<span>若你不填写，将导致其他人看不到，造成项目沟通不流畅的问题。</span>
    </p>
    <p>
      1.1.6
      在您使用联系人功能时，我们将收集您的通讯录信息，以便您可以发现和添加联系人为测量大师好友。<span>如果您拒绝提供这些信息，您将无法使用此项功能。</span>
    </p>
    <p>
      1.1.7
      当您参加测量大师内的营销活动（例如，在测量大师产品折扣活动）时，我们会收集您的姓名、手机号码、通讯地址。<span>收集这些信息是为了向您寄送测量大师硬件，如果您拒绝提供这些信息，我们将无法向您发放硬件产品。</span>
    </p>
    <p>
      1.1.8
      在您使用帮助中心管理账号时，根据您所使用的服务类型（包括但不限于找回登录名、找回密码、修改密码、绑定手机等），您可能需要向我们提供昵称、原密码、新的绑定手机号码等不同的信息，实现不同的账号管理功能。<span>如果您拒绝提供这些信息，您将无法使用该项信息对应的功能，但是不会影响您使用测量大师的其他功能。</span>
    </p>
    <p>
      1.1.9
      我们如在上述场景之外获得您的个人信息，将重新征得您的明示同意，并在获得您明示同意前向您充分说明应用场景与获取您相关信息的内容与范围。
    </p>
    <p>
      1.1.10
      我们如在上述场景之外获得您的个人信息，将重新征得您的明示同意，并在获得您明示同意前向您充分说明应用场景与获取您相关信息的内容与范围。
    </p>

    <p>1.2 我们间接收集的个人信息</p>
    <p>
      1.2.1
      当您在测量大师中使用接入的第三方应用时，我们为提供对接技术支持等目的，可能从此类第三方应用的开发者及其技术服务提供商处获取您必要的个人信息，例如设备信息、操作行为日志信息。
    </p>
    <p>
      <span>
        1.2.2
        如果涉及您的个人敏感信息，我们将单独向您取得授权。如您拒绝我们收集相关个人信息，可能导致第三方应用的全部或部分功能无法实现，但是不影响您使用测量大师的其他功能。</span>
    </p>
    <p>
      1.2.3
      此外，此类第三方应用的开发者及技术服务提供商可能依据其提供的隐私政策直接收集、使用您的个人信息，但我们不会获取这些个人信息。我们建议您在接受相关服务和提供个人信息前阅读并确认理解该等隐私政策。
    </p>

    <p>1.3 我们自动收集的个人信息</p>
    <p>
      1.3.1
      当您通过网站、客户端、小程序等服务入口使用我们的服务时，我们可能自动从您的设备处收集您的IP地址、操作系统版本、设备设置、唯一设备标识符、网络设备硬件MAC地址、中国移动UAID（仅限安卓设备）等软硬件特征信息、设备型号、唯一设备识别码、浏览器型号、粗略位置信息（例如国家或者城市信息）、安装的应用信息以及其他的设备技术信息，我们也会收集一些您使用我们服务的日志信息，例如访问过的页面或者点击过的链接。
    </p>
    <!-- <p>
      <span>1.3.2
        收集该等信息主要为了保障服务的安全运行，也可以使我们更好地了解您，比如您来自哪里以及我们的服务有哪些吸引您的内容。我们将该等信息用于内部分析，以及为您提升服务质量和服务相关性（如向您推荐您可能会感兴趣的信息和内容）。我们同时也需要留存相应的日志来记录我们网站、移动客户端等服务入口的运行状态，以符合中国法律法规的规定。</span>
    </p> -->
    <p>
      1.3.2
      这些信息一般通过我们使用的cookie或者类似的追踪科技来进行收集，详情请见下方的“Cookie及相关技术告知”部分。
    </p>

    <p>1.4 我们获取的设备权限</p>
    <p>
      1.4.1
      为向您提供便捷、优质的服务，我们可能会调用您设备上的一些权限。在您使用相应功能时会看到弹窗提醒，询问您是否授权。<span>您可以在客户端或设备的设置功能中选择关闭部分或全部权限。在不同设备中，上述权限的开启和关闭方式可能有所不同，具体请参考设备及系统开发方的说明或指引。
        关于测量大师调用权限的具体情况，请见《设备权限清单》。</span>
    </p>

    <p>
      1.5
      当涉及国家安全与利益、社会公共利益、与犯罪侦查有关的相关活动，或者涉及您或他人生命财产安全但在特殊情况下无法获得您的及时授权，以及能够从合法公开的渠道获取或法律法规规定的其他情形下，我们可能会在不经过您的同意情况下收集您的个人信息。
    </p>
    <p>
      1.6
      请您注意，<span>除非您删除或通过系统设置拒绝我们收集，在您使用测量大师期间，我们将在符合本政策所声明的范围内使用您的个人信息。但是，在法律法规允许的情形下，我们也会将您的个人信息用于与上述目的相关或者其他法律法规允许的用途，例如在对您的个人身份信息进行去标识化处理且不透露您的其他个人信息的前提下，对您去标识化处理后的个人信息进行分析和商业化利用。</span>
    </p>
    <p>
      1.7
      我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android
      ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
      信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。
      使用SDK名称：友盟SDK， 友盟SDK隐私权政策链接：<a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a>。
    </p>
    <p>
      1.8
      我们要收集存储权限，获取设备序列号。
    </p>
    <h3>2 Cookie及相关技术告知</h3>
    <p>
      <span>2.1.1
        Cookie和相关技术可以帮助网站辨认注册用户，计算用户数量，通常被各网站用来判定完成注册的用户是否已经实现登录，我们使用cookie及相关技术主要为了实现以下功能或服务：
        保障产品或服务的安全、高效运转。我们可能会设置认证与保障安全性的cookie，以便确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</span>
    </p>
    <p>
      2.1.2
      简化您的操作步骤。使用此类技术，可以帮助您省去重复填写个人信息、输入搜索内容的步骤和流程，帮您获得更轻松的访问体验。
    </p>
    <p>
      <span>2.1.3
        如您不希望个人信息保留在cookies中，您可以对浏览器进行配置：选择"拒绝cookies"或"当网站发送cookies时通知您"。鉴于我们的服务是通过支持cookies来实现的，完成关闭cookies的操作后，您访问测量大师可能受到影响或不能充分获得我们的服务。</span>您不禁用cookies时，可能会得到提示，例如是否在下一次进入此网站时保留用户信息以便简化登录手续（如自动登录）。
    </p>
    <h3>3 我们如何委托处理、共享、转让及公开披露您的个人信息</h3>

    <p>3.1 为了提升信息处理效率</p>
    <p>
      3.1.2<span>我们可能会委托第三方代表我们处理您的个人信息。对我们委托处理个人信息的第三方，我们会与其签署严格的保密协定，要求其按照我们的要求、本政策以及法律法规要求的保密和安全措施来处理个人信息。</span>
    </p>
    <p>
      <span>
        3.1.3
        除非经过您本人事先授权同意，我们不会向第三方共享、转让可识别您身份的个人信息。我们可能共享、转让经去标识化或匿名化处理后的个人信息。</span>
    </p>
    <p>
      3.1.4
      <span>特定前提下，我们可能将您的个人信息与第三方共享，但我们只会共享必要的个人信息，且第三方受本政策中所声明的目的的约束。</span>我们会按照法律法规及国家标准的要求以确认协议、<span>具体场景下的文案确认、弹窗提示等形式征得您的同意，或确认第三方已经征得您的同意。第三方如要改变个人信息的处理目的，其将再次征求您的授权同意。</span>
    </p>
    <p>
      <span>3.1.5
        对我们与之共享个人信息的第三方，我们会评估其收集信息的合法性、正当性和必要性，对其数据安全环境进行调查，并在可行的情况下与其签署严格的保密协定，要求他们按照法律法规规定、我们的说明、本政策以及其他任何相关的安全措施来处理个人信息。</span>
    </p>
    <!-- <p>3.2 您同意我们基于如下目的与第三方共享信息：</p>

    <p>3.2.1 实现测量大师的服务及功能的信息共享</p>
    <p>
      3.2.2
      请您理解，测量大师的某些功能和服务由第三方（包括我们的关联方）提供，为实现该等功能和服务，我们可能会与相关第三方共享您必要的信息，以便这些第三方向您提供相关的功能和服务，向您展示或推送您可能感兴趣的信息和内容。目前这类第三方主要包括软件服务提供商、智能设备提供商或系统服务提供商。
    </p>
    <p>
      3.2.3
      例如，当第三方软件、设备系统与测量大师产品或服务结合为您提供基于位置的服务时，<span>测量大师可能会基于您对系统定位的授权及设定，获取您的位置信息及设备信息，并经过去标识化后提提供给前述合作伙伴。特殊情境下会包含您另行填写的联系信息。如您拒绝此类信息的收集及使用，您需要在设备系统中进行设置或关闭提供服务的软件，在测量大师内可能无法自动或手动设置关闭相关服务。</span>
    </p>
    <p>
      3.2.4
      另外，当您使用测量大师账号登录第三方产品或与第三方账号绑定时，我们会将您测量大师账号的头像、昵称及其他您授权的信息共享至运营前述产品的第三方。
    </p>
    <p>3.2.5 帮助您参加营销推广活动的信息共享</p>
    <p>
      3.2.6
      为帮助您参加营销推广活动，我们可能会与第三方共享您的信息。<span>当您选择参加我们举办的营销推广活动时，根据不同活动的需要，您可能需要向我们提供姓名、手机号码、通信地址。经过您的明示同意，我们会将上述信息与第三方共享，以便第三方能及时向您提供服务。</span>
    </p> -->
    <p>3.2 实现广告相关的信息共享</p>
    <p>
      3.2.1
      为实现程序化广告推送，我们可能会与广告合作伙伴（包括广告主和其他广告服务提供商）共享去标识化或匿名化处理后的信息。<span>未经您授权，我们不会将您的个人身份信息（即可以识别您身份的信息，例如姓名或手机号码）与广告合作伙伴共享。我们只会将匿名化的用户画像和经加密处理的设备信息与广告合作伙伴共享，以帮助其在不识别您个人身份的前提下提升广告的有效触达率。</span>
    </p>
    <p>
      3.2.2
      例如，只有在广告合作伙伴同意遵守我们的广告发布规范后，我们才会告诉广告合作伙伴其广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些合作伙伴提供不能识别您个人身份的统计信息，帮助他们了解其受众群体。
    </p>
    <p>3.3 实现安全与分析统计的信息共享</p>
    <p>
      <span>
        3.3.1
        为维护您和其他测量大师用户的正当合法权益，保障测量大师服务的稳定运行，我们会与数据安全合作伙伴共享脱敏后的您的设备信息、联网信息。此外，为分析测量大师服务的使用情况，提升您的使用体验，我们会与数据分析合作伙伴共享脱敏后的您的测量大师使用行为情况信息。</span>
    </p>
    <p>
      3.4
      测量大师服务中某些功能的技术模块由第三方SDK协助提供，有关测量大师客户端中接入的第三方SDK收集和使用用户信息的具体情况，请见如下：
    </p>
    <p>3.4.1 Android操作系统第三方SDK列表</p>
    <div class="tabs">
      <div><strong>微信SDK：</strong></div>
      <p>应用场景：微信授权登录，微信好友和微信朋友圈分享功能</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：腾讯</p>
      <p>隐私政策链接：<a href="https://privacy.qq.com/">点击查看</a></p>
    </div>

    <div class="tabs">
      <div><strong>高德地图SDK：</strong></div>
      <p>应用场景：场景地理围栏功能，用户自定义设置地理区域，当用户进入该区域后自动执行场景功能。</p>
      <p>收集个人信息的类型：位置信息（GPS位置、WLAN接入点、蓝牙、WI-FI信息、SSID>、GNSS、基站以及其他传感器信息），设备信息（IMEI、
        IMSI. IDFA. Android ID. MEID, MACtEtt, OAID.IMSI、硬件序列号、操作系统版本信息、已安装app），IP地址。
      </p>
      <p>第三方SDK提供方：高德软件有限公司</p>
      <p>隐私政策链接：<a href="https://lbs.amap.com/pages/privacy/">点击查看</a></p>
    </div>

    <div class="tabs">
      <div><strong>Android 定位 SDK：</strong></div>
      <p>应用场景：当用户使用定位功能时，在移动终端设备向用户提供定位信息。</p>
      <p>收集个人信息的类型：OAID、系统属性、操作系统及版本、系统设置、运营商基站信息（Cell id）、WIFI信息（WiFi状态、WIFI参数、附近的WIFI列表、连接的WIFI）、传感器信息（重力传感器、陀螺仪传感器 、加速度传感器 、磁场传感器 、旋转矢量传感器） 、蓝牙信息、精确位置信息（GPS信息）。
      </p>
      <p>第三方SDK提供方：深圳市腾讯计算机系统有限公司</p>
      <p>隐私政策链接：<a href="https://lbs.qq.com/mobile/androidLocationSDK/androidGeoGuide/androidGeoOverview">点击查看</a></p>
    </div>

    <div class="tabs">
      <div><strong>Bugly：</strong></div>
      <p>应用场景：收集用户手机错误信息，发生错误时上报</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：腾讯</p>
      <p>隐私政策链接：<a href="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56">点击查看</a></p>
    </div>

    <div class="tabs">
      <div><strong>友盟SDK：</strong></div>
      <p>应用场景：用于埋点收集、bug统计、用户活跃度统计</p>
      <p>收集个人信息的类型：获取陀螺仪传感器,BSSID,SSID个人信息,SD卡数据</p>
      <p>第三方SDK提供方：友盟</p>
      <p>隐私政策链接：<a href="https://www.umeng.com/policy">点击查看</a></p>
    </div>

    <div class="tabs">
      <div><strong>移动SDK：</strong></div>
      <p>应用场景：用于本机号码验证注册或登录</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：中国移动</p>
      <p>
        隐私政策链接：<a href="https://wap.cmpassport.com/resources/html/contract.html">点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>电信SDK：</strong></div>
      <p>应用场景：用于本机号码验证注册或登录</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：中国电信</p>
      <p>
        隐私政策链接：<a href="http://189.cn/fj/sy_yw/sy_yw_gwzn/101960.html">点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>联通SDK：</strong></div>
      <p>应用场景：用于本机号码验证注册或登录</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：中国联通</p>
      <p>
        隐私政策链接：<a href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true">点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>支付宝SDK：</strong></div>
      <p>应用场景：用于提供支付宝支付或提现功能</p>
      <p>收集个人信息的类型：网络状态信息 设备信息 本机号码</p>
      <p>第三方SDK提供方：支付宝</p>
      <p>
        隐私政策链接：<a href="
https://render.alipay.com/p/c/k2cx0tg8">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>数字联盟SDK：</strong></div>
      <p>应用场景：用于提供设备唯一性识别和安全风控、推广活动反作弊服务</p>
      <p>收集个人信息的类型：设备信息</p>
      <p>第三方SDK提供方：数盟</p>
      <p>
        隐私政策链接：<a href="https://www.shuzilm.cn/main/privacy.html">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>支付宝核身SDK：</strong></div>
      <p>应用场景：提供支付宝核实身份等能力</p>
      <p>收集个人信息的类型：网络状态信息本机号码设备信息存储信息位置信息</p>
      <p>第三方SDK提供方：支付宝</p>
      <p>
        隐私政策链接：<a href="https://render.alipay.com/p/c/k2cx0tg8">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>小米推送SDK：</strong></div>
      <p>应用场景：用于实现对小米品牌的手机进行消息推送</p>
      <p>收集个人信息的类型：网络状态信息设备信息存储信息</p>
      <p>第三方SDK提供方：小米</p>
      <p>
        隐私政策链接：<a href="https://dev.mi.com/console/doc/detail?pId=1822">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>Vivo推送SDK：</strong></div>
      <p>应用场景：用于实现对Vivo品牌的手机进行消息推送</p>
      <p>收集个人信息的类型：存储信息</p>
      <p>第三方SDK提供方：步步高</p>
      <p>
        隐私政策链接：<a href="https://www.vivo.com.cn/about-vivo/privacy-policy">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>魅族推送SDK：</strong></div>
      <p>应用场景： 用于实现对魅族品牌的手机进行消息推送</p>
      <p>收集个人信息的类型：网络状态信息设备信息存储信息位置信息</p>
      <p>第三方SDK提供方：珠海市魅网科技有限公司</p>
      <p>
        隐私政策链接：<a href="https://i.flyme.cn/privacy?lang=zh_CN">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>华为推送SDK：</strong></div>
      <p>应用场景：用于实现对华为品牌的手机进行消息推送</p>
      <p>收集个人信息的类型：网络状态信息设备信息存储信息位置信息</p>
      <p>第三方SDK提供方：华为</p>
      <p>
        隐私政策链接：<a href="https://www.huawei.com/cn/privacy-policy">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>设备唯一标志符SDK：</strong></div>
      <p>应用场景：获取OAID替代IMEI</p>
      <p>收集个人信息的类型：设备信息</p>
      <p>第三方SDK提供方：中国信息通信研究院</p>
      <p>
        隐私政策链接：<a href="http://www.msa-alliance.cn/col.jsp?id=120">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>OPPO推送SDK：</strong></div>
      <p>应用场景：用于实现对OPPO品牌的手机进行消息推送</p>
      <p>收集个人信息的类型：设备信息</p>
      <p>第三方SDK提供方：广东欢太科技有限公司</p>
      <p>
        隐私政策链接：<a href="https://open.oppomobile.com/wiki/doc#id=10288">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>face++SDK：</strong></div>
      <p>应用场景：用户身份识别</p>
      <p>收集个人信息的类型：设备信息</p>
      <p>第三方SDK提供方：旷视科技</p>
      <p>
        隐私政策链接：<a href="https://www.faceplusplus.com.cn/privacy-policy/">
          点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>华为相机SDK：</strong></div>
      <p>应用场景：增强Camera获取图片、视频的效果</p>
      <p>收集个人信息的类型：设备信息</p>
      <p>第三方SDK提供方：华为</p>
      <p>
        隐私政策链接：<a href="https://consumer.huawei.com/en/legal/privacy-policy/worldwide/">
          点击查看</a>
      </p>
    </div>

    <p>IOS操作系统第三方SDK列表</p>
    <div class="tabs">
      <div><strong>移动SDK：</strong></div>
      <p>应用场景：向您提供手机号一键登录验证功能</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：中国移动</p>
      <p>
        隐私政策链接：<a href="https://ecloud.10086.cn/op-help-center/show/544F919C8C207DC4">点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>联通SDK：</strong></div>
      <p>应用场景：向您提供手机号一键登录验证功能</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：中国联通</p>
      <p>
        隐私政策链接：<a href="https://cuopen.10010.com/guide-privacy/">点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>电信SDK：</strong></div>
      <p>应用场景：向您提供手机号一键登录验证功能</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：中国电信</p>
      <p>
        隐私政策链接：<a href="https://id.189.cn/api?initialSrc=/html/api_detail_109.html">点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>微信SDK：</strong></div>
      <p>应用场景：微信授权登录，微信好友和微信朋友圈分享功能</p>
      <p>收集个人信息的类型：设备信息本机号码</p>
      <p>第三方SDK提供方：腾讯</p>
      <p>隐私政策链接：<a href="https://privacy.qq.com/">点击查看</a></p>
    </div>
    <div class="tabs">
      <div><strong>极验SDK：</strong></div>
      <p>应用场景：用于提供识别与拦截机器程序批量自动化操作</p>
      <p>收集个人信息的类型：位置信息设备信息</p>
      <p>第三方SDK提供方：武汉极意网络科技有限公司</p>
      <p>
        隐私政策链接：<a href="https://www.geetest.com/Private">点击查看</a>
      </p>
    </div>
    <div class="tabs">
      <div><strong>支付宝SDK：</strong></div>
      <p>应用场景：提供支付宝支付、提现、核身等能力</p>
      <p>收集个人信息的类型：设备信息 网络状态信息</p>
      <p>第三方SDK提供方：支付宝</p>
      <p>
        隐私政策链接：<a href="https://render.alipay.com/p/c/k2cx0tg8">点击查看</a>
      </p>
    </div>

    <div class="tabs">
      <div><strong>极光隐私政策：</strong></div>
      <p>应用场景：为 APP 用户提供信息推送服务</p>
      <p>收集个人信息的类型：设备标识符（包括Android ID、GAID、OAID、UAID、IDFA、AAID）、设备硬件信息（包括设备型号、设备屏幕分辨率、设备硬件制造商、设备产品名称、设备存储空间）、操作系统信息（包括操作系统版本、系统名称、系统语言）、网络信息（包括网络类型、运营商信息、IP地址、WIFI状态信息）</p>
      <p>推送信息日志：必要个人信息，用于推送服务基本业务功能场景中生成脱敏的终端用户设备唯一性标识，保证服务在不同设备上的兼容性和准确识别，同时了解推送信息送达的情况。</p>
      <p>设备标识符（包括IMEI、MAC、IMSI）：可选个人信息，用于推送服务基本业务功能场景中生成终端用户设备唯一标识的补充，提升唯一设备标识的准确性，进而提升推送的精确度和触达率</p>
      <p>网络信息（包括SSID、BSSID、WIFI列表信息、基站信息）：可选个人信息，用于推送服务基本业务功能场景中判定模糊位置信息，选择就近推送服务节点提供推送服务，提升推送触达率。</p>
      <p>位置相关信息：地理围栏、用户分群推送扩展功能所必要个人信息，用于提供线下场景的精细化推送，根据位置信息推荐更符合您需要的推送内容；</p>
      <p>数据处理方式：通过去标识化、加密传输及其他安全方式</p>
      <p>第三方SDK提供方：极光推送</p>
      <p>
        隐私政策链接：<a href="https://www.jiguang.cn/license/privacy">点击查看</a>
      </p>
    </div>

    <p>
      3.5
      未经您本人同意，我们(指无锡中巢建材有限公司)不会向第三方共享、转让、公开披露您的个人信息，下列情形除外：
    </p>
    <p>3.5.1 事先获得您的明确授权同意；</p>
    <p>3.5.2 与我们履行适用法律法规规定的义务相关的；</p>
    <p>3.2.3 与国家安全、国防安全直接相关的；</p>
    <p>3.5.4 与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>3.5.5 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
    <p>
      3.5.6
      出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </p>
    <p>
      3.5.7 您自行向社会公众公开的个人信息；以及
      从合法公开披露的信息中收集的您的个人信息，如合法的新闻报道、政府信息公开等渠道
      您应当理解，您在使用测量大师服务过程中，可以向特定的人发送照片、身份信息、位置信息等个人信息，也可以基于我们的服务向不特定人共享自己的照片、位置信息、身份信息等个人信息。您因为这些主动共享、披露行为导致您个人信息被他人知晓的，我们不承担相关责任。
    </p>

    <h3>4 我们如何保护您的个人信息</h3>
    <p>
      我们将尽一切合理努力保护我们获得的您的个人信息，并由专门的数据安全部门对个人信息进行保护，防止您的个人信息在意外的、未经授权的情况下被非法访问、复制、修改、传送、遗失、破坏、处理或使用。
    </p>
    <p>我们采取以下技术措施来保护您的个人信息：</p>
    <p>
      我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户个人信息在传输过程中的安全；
    </p>
    <p>通过采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离；</p>
    <p>
      在个人信息使用时，例如个人信息展示、个人信息关联计算时，我们会采用包括内容替换、加密脱敏等多种数据脱敏技术增强个人信息在使用中的安全性；
    </p>
    <p>
      尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但我们仍然无法保证您的个人信息通过不安全途径进行交流时的安全性。因此，您应采取积极措施保证个人信息的安全，例如定期修改账号密码，不将自己的账号密码等个人信息透露给他人。
    </p>
    <p>
      网络环境中始终存在各种信息泄漏的风险，当出现意外事件、不可抗力等情形导致您的信息出现泄漏时，我们将极力控制局面，及时告知您事件起因、我们采取的安全措施、您可以主动采取的安全措施等相关信息。如发生个人信息有关的安全事件，我们将第一时间向有关主管机关报备，并及时进行问题排查，采取应急措施。同时，我们会向全量用户发送更改密码的通知提醒，或通过电话、短信等各种方式触达用户，并在测量大师公共运营平台进行告知。
    </p>
    <p>
      请您理解，我们提供的个人信息保护措施仅适用于测量大师。一旦您离开测量大师，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在测量大师以外的网站提交的任何个人信息，无论您登录或浏览上述网站是否基于测量大师的链接或引导。
    </p>
    <h3>5 我们如何存储您的个人信息</h3>
    <p>
      5.1
      我们依照法律法规的规定，将境内收集的您的个人信息存储于中华人民共和国境内。目前，我们不会将您的个人信息在跨境业务中使用、储存、共享和披露，或将您的个人信息传输至境外。
    </p>
    <p>
      5.2
      我们仅在本政策所述目的所必需的期间和法律法规及监管规定要求的时限内保留您的个人信息。
      我们将会按照《网络安全法》等相关法律法规规定的期限保存您的个人信息。超出法定保存期限后，我们会将您的个人信息删除或做匿名化处理。
    </p>
    <h3>6 您的个人信息主体权利</h3>
    <p>
      6.1
      我们非常重视您对个人信息的管理，并尽全力保障您对于个人信息的访问、更正、删除以及撤回同意的权利，使您拥有充分的能力维护您的隐私和个人信息安全。
    </p>
    <p>6.2 访问、修改、删除您的个人信息</p>
    <p>
      6.2.1
      当您完成测量大师的账号注册后，您可以随时访问、修改您提交给我们的个人信息。发生以下情形，您可以向我们提出删除个人信息的请求：
    </p>
    <p>6.2.2 如果我们处理个人信息的行为违反法律法规；</p>
    <p>6.2.3 如果我们处理个人信息的行为违反了与您的约定；</p>
    <p>6.2.4 如果我们终止测量大师的运营及服务。</p>
    <p>
      6.2.5
      若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您个人信息的第三方，要求其及时删除，除非法律法规另有规定或这些第三方已获得您的独立授权。
    </p>
    <p>
      6.2.6
      对难以直接在客户端访问、修改、删除的个人信息，您也可以通过本政策提供的联系方式，将相关申请提交给我们。
    </p>
    <p>6.3 注销账号</p>
    <p>
      6.3.1
      您可以通过测量大师官方客户端注销账号。请您理解，注销账号是不可恢复的操作。一旦您注销您的账号，我们将删除有关您账号的一切信息（包括您使用同一账号登录的其他新格尔集团应用中的信息），但法律法规另有规定的除外。您也无法通过已注销的账号重新获得测量大师及其他新格尔集团应用的相关服务。
    </p>
    <p>6.4 响应您的请求</p>
    <p>
      6.4.1
      为保障安全，我们会在验证您身份后及时处理您的请求。对于无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。如存在以下情形之一时，按照法律法规要求，我们可能无法响应您的请求。
    </p>

    <h3>7 本政策的更新</h3>
    <p>
      7.1
      为给您提供更好的服务以及随着我们业务的发展，本政策也会随之更新。对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于短信、或在浏览页面做特别提示等方式，说明本政策的具体变更内容）。本政策所指的重大变更包括但不限于：
    </p>
    <p>
      7.2
      我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p>
      7.3
      我们在所有权结构、组织架构等方面发生重大变化，导致实际收集和处理您的个人信息的主体发生变化，如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p>7.4 个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p>7.5 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p>
      7.6 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p>7.7 个人信息安全影响评估报告表明存在高风险时。</p>
    <p>
      7.8
      我们会在网站、客户端（设置-“关于测量大师”）上发出更新版本，并在生效前通过网站公告或其他适当方式提醒您相关内容的更新，以便您及时了解最新版本的本政策。您同意并继续使用测量大师，即意味着您同意本政策（含更新版本）的内容。
    </p>

    <h3>8 自启动或关联启动说明</h3>
    <p>
      8.1
      推送相关: 为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的;当您打开内容类推送消息，在征得您的明确同意后，会跳转打开相关内容。在未征得您同意的情况下，则不会有关联启动。
    </p>

    <h3>9 如何联系我们</h3>
    <p>
      9.1
      如您对于本政策或相关事宜有任何问题，特别是认为我们的个人信息处理行为损害了您的合法权益，您可随时通过以下方式与我们取得联系。我们将尽快审核所涉问题，并按照法律法规的要求和期限及时予以回复。
    </p>
    <p>9.2 服务热线：0510-85186908</p>
    <p>
      9.3
      常用办公地址：无锡市滨湖区雪浪小镇秀水坊2楼（无锡中巢建材科技有限公司）
    </p>
  </div>
</template>

<script>
  export default {
    name: "privacy",
    data() {
      return {};
    }
  };
</script>

<style lang="less" scoped>
  .privacy {
    margin: 35px 20px;

    .tabs {
      text-indent: 25px;

      p {
        // text-indent: 50px;
      }
    }

    p {
      margin: 8px 0;
      text-indent: 25px;
      font-size: 14px;
      line-height: 25px;
      color: #666666;

      span {
        font-weight: 600px;
      }
    }

    &>div {
      line-height: 25px;
      font-size: 14px;
      margin: 15px 0;

      &.title {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        line-height: 30px;
      }
    }

    p {
      margin: 8px 0;
      text-indent: 25px;
      font-size: 14px;
      line-height: 25px;
      color: #666666;

      span {
        font-weight: 600px;
      }
    }

    h3 {
      color: #333333;
      font-size: 18px;
      font-weight: 600;
    }

    h4 {
      margin: 10px 0;
      font-weight: 600;
      font-size: 16px;
    }

    h5 {
      line-height: 25px;
      font-weight: 600;
      font-size: 14px;
    }
  }
</style>